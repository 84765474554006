import React from "react";
import './tableQr.css'

export default function TableQR({dataQR, type}) {

  return (
    <div className="container">
      {
        type === 'fonda' && (
        <table className="rwd-table">
          <tbody>
            <tr>
              <th># Empleado</th>
              <th>Nombre</th>
              <th>Fecha de Comida</th>
            </tr>
            {
              dataQR ? dataQR.map((empleado)=>
              <tr key={empleado.id}>
                <td data-th="# Empleado">{empleado.codigo_colaborador}</td>
                <td data-th="Nombre">{empleado.colaborador_id[1]}</td>
                <td data-th="Fecha">{empleado.fecha_registro}</td>
              </tr>) : <tr><td colSpan={4}>Sin registros de comida</td></tr>
            }
          </tbody>
        </table>
        )
      }
    </div>
  );
}
