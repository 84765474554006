import React from "react";

export default function svgQr() {
  return (
    <>
      {/*?xml version="1.0" standalone="no"?*/}
      <svg
        version={1.0}
        xmlns="http://www.w3.org/2000/svg"
        className="svg"
        viewBox="0 0 626.000000 413.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,413.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1125 3132 c-64 -22 -109 -54 -145 -104 -63 -86 -61 -51 -58 -984 l3
-849 29 -53 c33 -60 76 -101 139 -134 l42 -23 851 -3 c836 -2 853 -2 906 18
65 24 126 74 153 124 l19 36 991 2 990 3 68 32 c82 39 154 110 194 190 l28 58
0 640 0 640 -27 50 c-36 68 -101 128 -173 162 l-60 28 -1005 3 -1005 3 -27 44
c-14 24 -46 58 -69 74 -86 63 -61 61 -968 61 -758 -1 -830 -2 -876 -18z m1743
-97 c47 -20 79 -50 103 -97 18 -36 19 -71 19 -873 0 -750 -2 -839 -16 -868
-23 -45 -53 -76 -96 -98 -36 -18 -71 -19 -873 -19 -802 0 -837 1 -873 19 -43
22 -73 53 -96 98 -14 29 -16 118 -16 868 0 802 1 837 19 873 24 46 56 76 101
96 30 13 146 15 863 16 725 0 832 -2 865 -15z m658 -306 c51 -13 104 -66 104
-103 0 -23 -4 -24 -61 -28 -53 -3 -62 0 -72 17 -14 27 -27 35 -58 35 -31 0
-57 -26 -42 -41 5 -5 42 -18 82 -29 41 -12 87 -28 103 -36 92 -48 86 -181 -11
-237 -67 -40 -219 -31 -269 16 -29 27 -52 69 -52 97 0 17 6 20 43 20 23 0 51
3 62 5 15 4 24 -2 35 -27 18 -40 58 -57 95 -40 17 8 25 20 25 36 0 31 -3 33
-93 57 -89 25 -133 57 -148 108 -33 110 101 189 257 150z m466 1 c45 -13 93
-52 114 -92 19 -37 13 -43 -51 -56 -49 -11 -51 -10 -73 19 -28 35 -77 39 -113
10 -20 -16 -24 -30 -27 -92 -3 -64 -1 -78 18 -101 30 -39 90 -39 119 -2 12 15
21 33 21 40 0 8 3 14 6 14 15 0 114 -32 118 -38 10 -17 -40 -96 -77 -120 -36
-24 -51 -27 -121 -27 -98 0 -147 21 -191 84 -27 38 -30 52 -33 129 -3 76 0 93
20 131 13 25 35 53 49 63 58 43 146 58 221 38z m549 -213 c43 -115 79 -213 79
-218 0 -6 -31 -9 -72 -7 l-72 3 -8 33 -9 33 -77 -3 -76 -3 -10 -32 -9 -33 -68
0 c-38 0 -69 4 -69 9 0 6 90 251 149 404 11 27 12 28 87 25 l76 -3 79 -208z
m329 102 c41 -61 77 -112 80 -114 3 -2 5 47 5 108 l0 112 68 3 67 3 0 -221 0
-220 -68 0 -68 0 -74 111 -75 111 -3 -111 -3 -111 -69 0 -70 0 0 220 0 220 68
0 68 0 74 -111z m358 -871 c-3 -269 -4 -285 -25 -324 -29 -55 -111 -127 -161
-142 -29 -9 -285 -12 -991 -12 l-951 0 0 373 c0 206 3 377 7 380 3 4 483 7
1065 7 l1059 0 -3 -282z"
          />
          <path
            d="M1230 2605 l0 -225 230 0 230 0 0 225 0 225 -230 0 -230 0 0 -225z
m368 3 l-3 -133 -135 0 -135 0 -3 133 -3 132 141 0 141 0 -3 -132z"
          />
          <path d="M1420 2604 l0 -45 43 3 c42 3 42 3 45 46 l3 42 -45 0 -46 0 0 -46z" />
          <path
            d="M1780 2695 l0 -135 90 0 90 0 0 -45 0 -45 90 0 90 0 0 -45 0 -45 45
0 45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 135 0 135 -45 0 -45 0 0
-45 0 -45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -45 0
-45 -90 0 -90 0 0 90 0 90 -45 0 -45 0 0 -135z m360 -90 l0 -45 -45 0 -45 0 0
45 0 45 45 0 45 0 0 -45z"
          />
          <path
            d="M2320 2605 l0 -225 230 0 230 0 0 225 0 225 -230 0 -230 0 0 -225z
m368 3 l-3 -133 -137 -3 -138 -3 0 136 0 135 140 0 141 0 -3 -132z"
          />
          <path d="M2502 2608 c3 -43 3 -43 44 -46 l42 -3 4 45 3 46 -48 0 -48 0 3 -42z" />
          <path
            d="M1780 2380 l0 -90 45 0 45 0 0 -45 0 -45 -90 0 -90 0 0 45 0 45 -45
0 -45 0 0 -45 0 -45 -45 0 -45 0 0 45 0 45 -140 0 -140 0 0 -44 0 -45 48 -3
47 -3 0 -40 0 -40 -47 -3 -48 -3 0 -89 0 -89 48 -3 47 -3 0 -42 0 -43 93 0 92
0 0 45 0 45 45 0 45 0 0 -45 0 -45 90 0 90 0 0 -90 0 -90 45 0 45 0 0 -45 0
-45 -45 0 -45 0 0 -138 0 -137 45 3 45 4 0 45 0 44 43 -3 42 -3 3 -45 3 -45
44 3 c44 4 44 4 47 45 3 42 3 42 46 45 l42 3 0 -44 0 -45 45 -4 44 -3 3 90 3
90 43 3 42 3 0 -89 0 -90 45 -4 44 -3 3 45 3 45 43 3 42 3 0 -45 0 -46 141 0
140 0 -3 42 c-3 41 -3 42 -43 42 -45 1 -45 1 -45 56 l0 40 45 0 45 0 0 90 0
90 -96 0 -95 0 3 43 3 42 93 3 92 3 0 44 0 45 -44 0 -44 0 -4 41 c-2 22 -2 42
1 45 2 2 24 4 48 4 l43 0 0 90 0 90 -45 0 -45 0 0 -45 0 -45 -95 0 -95 0 0 45
0 45 48 0 47 0 0 42 0 43 93 3 92 3 0 44 0 45 -140 0 -140 0 0 -45 0 -45 -45
0 -45 0 0 45 0 45 -180 0 -180 0 0 45 0 45 -45 0 -45 0 0 -45 0 -45 -45 0 -45
0 0 90 0 90 -45 0 -45 0 0 -90z m-270 -225 l0 -45 45 0 45 0 0 -45 0 -45 -45
0 -45 0 0 44 0 45 -47 3 c-44 3 -48 5 -51 31 -6 48 3 57 53 57 l45 0 0 -45z
m450 0 l0 -45 45 0 45 0 0 45 0 45 45 0 45 0 0 -45 0 -45 -45 0 -45 0 0 -45 0
-45 -45 0 -45 0 0 -45 0 -45 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45
-45 0 -45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 90 0 90 45 0 45 0
0 -45 0 -45 90 0 90 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 -45z m450
-45 l0 -90 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 90 0 90 0 0 -90z
m-992 -138 l3 -43 -48 3 -48 3 -3 43 -3 43 48 -3 48 -3 3 -43z m812 3 l0 -45
-45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 -45 0
-45 0 0 45 0 45 -45 0 -45 0 0 -90 0 -90 45 0 45 0 0 -45 0 -45 -45 0 -45 0 0
45 0 45 -45 0 -45 0 0 90 0 90 45 0 45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45
0 45 0 0 45 0 45 45 0 45 0 0 -45z m180 -90 l0 -45 44 0 45 0 3 43 3 42 45 0
45 0 0 -40 0 -40 -47 -3 -48 -3 0 -45 0 -44 -45 0 -45 0 0 45 0 45 -45 0 -45
0 0 45 0 45 45 0 45 0 0 -45z m90 -270 l0 -45 -45 0 -45 0 0 -45 0 -45 -46 0
-45 0 3 43 c3 42 3 42 46 45 41 3 42 4 42 41 0 47 4 51 52 51 l38 0 0 -45z
m-540 -90 l0 -45 46 0 45 0 -3 -47 c-3 -46 -4 -48 -33 -49 -49 -1 -55 5 -55
52 l0 44 -45 0 -45 0 0 45 0 45 45 0 45 0 0 -45z m728 -2 l3 -43 -50 0 -51 0
0 38 c0 49 5 54 55 50 39 -3 40 -4 43 -45z m-94 -65 c12 -46 7 -67 -18 -74
-49 -12 -71 -2 -74 34 -5 52 3 62 47 62 32 0 40 -4 45 -22z"
          />
          <path
            d="M1230 1525 l0 -224 226 -3 c124 -2 228 -1 230 1 2 2 4 105 4 228 l0
223 -230 0 -230 0 0 -225z m365 -3 l0 -133 -135 -1 -135 0 -3 123 c-1 67 0
129 2 136 4 11 35 13 138 11 l133 -3 0 -133z"
          />
          <path d="M1420 1526 l0 -46 46 0 45 0 -3 43 c-3 42 -3 42 -45 45 l-43 3 0 -45z" />
          <path
            d="M4362 2535 c-12 -38 -20 -71 -18 -73 2 -2 21 -2 43 0 l39 3 -22 70
-21 70 -21 -70z"
          />
          <path
            d="M4772 1908 c-9 -9 -12 -77 -12 -244 0 -198 2 -235 16 -248 23 -23
245 -23 268 0 14 13 16 49 16 240 0 136 -4 233 -10 245 -10 17 -22 19 -138 19
-86 0 -132 -4 -140 -12z m248 -253 l0 -185 -110 0 -110 0 0 185 0 185 110 0
110 0 0 -185z"
          />
          <path
            d="M3250 1660 l0 -250 63 0 62 0 -1 186 c-1 102 1 182 5 179 3 -4 27
-86 52 -183 l46 -177 56 -3 56 -3 10 38 c5 21 27 106 48 188 l38 150 3 -187 2
-188 65 0 65 0 0 250 0 250 -103 0 -104 0 -38 -151 c-21 -84 -41 -145 -45
-137 -4 7 -22 75 -41 150 l-34 138 -102 0 -103 0 0 -250z"
          />
          <path
            d="M3920 1660 l0 -250 210 0 210 0 0 55 0 55 -135 0 -135 0 0 50 0 50
120 0 120 0 0 50 0 50 -120 0 -120 0 0 45 0 45 130 0 130 0 0 50 0 50 -205 0
-205 0 0 -250z"
          />
          <path
            d="M4420 1853 c0 -53 16 -210 26 -260 4 -20 11 -23 48 -23 l43 0 16 138
c10 75 17 152 17 170 l0 32 -75 0 -75 0 0 -57z"
          />
          <path d="M4430 1470 l0 -60 65 0 65 0 0 60 0 60 -65 0 -65 0 0 -60z" />
        </g>
      </svg>
    </>
  );
}
