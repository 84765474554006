import "./App.css";
import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { useGeolocated } from "react-geolocated";
import SVGQR from "./svgQr";
import LoaderQR from "./loaderQR";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import TableQR from "./tableQR";

const TOAST_LIMIT = 1;
const URI_QR_FONDA ="https://g5adxisa62tqp35lbkx6zsqzsq0npmpd.lambda-url.us-east-1.on.aws/";
const URI_QR_VENTA ="https://5yvjwrzqxj4l2qevkgip4rhnxm0ezskd.lambda-url.us-east-1.on.aws/";
const HOST = "https://www.metropolitana.awsdata.dev";

function App() {
  const [textQR, setTextQR] = useState(null);
  const [dataQR, setDataQR] = useState(null);
  const [firstToken, setFirstToken] = useState("first");
  const { toasts } = useToasterStore();
  const queryParams = new URLSearchParams(window.location.search);
  const fonda = queryParams.get("fonda");
  const chofer = queryParams.get("chofer");
  const { coords } = useGeolocated({
    positionOptions: { enableHighAccuracy: fonda ? false : true },
    watchPosition: fonda ? false : true,
  });
  const latitude = coords?.latitude === undefined ? null : coords?.latitude;
  const longitude = coords?.longitude === undefined ? null : coords?.longitude;

  useEffect(() => {
    if (firstToken && fonda) {
      fetch(URI_QR_FONDA, {
        headers: {
          "Content-Type": "application/json",
          "Content-Length": firstToken.length,
          Host: HOST,
        },
        method: "POST",
        body: JSON.stringify({ firstToken, textQR: null, token: fonda }),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            return response.json();
          } else {
            throw Error("Sin registro de comida o token invalido");
          }
        })
        .then((data) => {
          setDataQR(data);
          setFirstToken(false);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id));

    if (textQR) {
      document.getElementById("loaderQRs").style.display = "flex";
      fetch(fonda ? `${URI_QR_FONDA}` : `${URI_QR_VENTA}`, {
        headers: {
          "Content-Type": "application/json",
          "Content-Length": textQR.length,
          Host: HOST,
        },
        method: "POST",
        body: JSON.stringify({
          textQR,
          token: fonda ? fonda : chofer,
          firstToken: false,
          lat: latitude,
          lon: longitude,
        }),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            return response.json();
          } else {
            if (response.status === 401) {
              throw Error("QR ya fue validado");
            }
            if (response.status === 404) {
              throw Error("Token URL Incorrecto");
            }
            if (response.status === 406) {
              throw Error("Activa la Ubicacion y Actualiza");
            } else {
              throw Error(
                fonda ? `QR Invalido` : `QR invalido o ya fue validado`
              );
            }
          }
        })
        // Paralizamos Video,Loading y enviamos mensaje
        .then((data) => {
          const video = document.getElementById("video");
          video.currenTime = 0;
          video.play();
          setTextQR(null);
          document.getElementById("loaderQRs").style.display = "none";
          toast.success(
            fonda
              ? `Pago de comida registrado para \n ${data[0]["colaborador_id"][1]}`
              : `Factura ${data.name} entregada `
          );
          setDataQR(data);
        })
        ///  Capturamos el ERROR,Paralizamos Video,Loading y enviamos mensaje
        .catch((error) => {
          const video = document.getElementById("video");
          video.currenTime = 0;
          video.play();
          setTextQR(null);
          document.getElementById("loaderQRs").style.display = "none";
          toast.error(error.message);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textQR, toast]);

  function handleScan(result, error) {
    if (!!result && fonda) {
      setTextQR(result?.text);
      document.getElementById("video").pause();
    }
    if (!!result && chofer) {
      setTextQR(result?.text);
      document.getElementById("video").pause();
    }
  }

  return (
    <>
      <div className="App">
        <div className="container-qr">
          <div className="logo_qr">
            <h1>Scan QR Code</h1>
            <div className="scan-logo"></div>
          </div>
          <div className="QR_div" id="QR_divs">
            <LoaderQR />
            <SVGQR />
            <QrReader
              className="QR"
              constraints={{ facingMode: "environment" }}
              videoContainerStyle={{
                borderRadius: "25px",
                border: "3px solid #D6D8EC",
              }}
              videoStyle={{
                borderRadius: "25px",
              }}
              onResult={handleScan}
              scanDelay={100}
            />
          </div>
        </div>

        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{ duration: fonda ? 4000 : 5000 }}
        />
      </div>
      <TableQR dataQR={dataQR} type={fonda ? "fonda" : "venta"} />
    </>
  );
}

export default App;
