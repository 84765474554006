import React from "react";

export default function loaderQR() {
  return (
    <div className="loaderQR" id="loaderQRs">
      {/*?xml version="1.0" encoding="utf-8"?*/}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx={50}
          cy={50}
          r={32}
          strokeWidth={2}
          stroke="#00c213"
          strokeDasharray="50.26548245743669 50.26548245743669"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="0.9615384615384615s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          />
        </circle>
        <circle
          cx={50}
          cy={50}
          r={29}
          strokeWidth={2}
          stroke="#ffffff"
          strokeDasharray="45.553093477052 45.553093477052"
          strokeDashoffset="45.553093477052"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="0.9615384615384615s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;-360 50 50"
          />
        </circle>
        {/* [ldio] generated by https://loading.io/ */}
      </svg>
    </div>
  );
}
